import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";

const appStore = createSlice({
    name: 'app',
    initialState: {
        language: localStorage.getItem('language') || 'tr',
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
            localStorage.setItem('language', action.payload);
        }
    }
})

export const {
    setLanguage,
} = appStore.actions;

const AppStore = appStore.reducer;

export const useAppStore = () => useSelector(state => state.appStore);

export default AppStore
