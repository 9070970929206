import React from 'react';
import {NavLink} from "react-router-dom";
import {route} from "utils/helpers";
import logo from "assets/img/logo.svg";

function Header(props) {
    return (
        <div className="bg-white shadow p-3 rounded flex items-center justify-between mb-5">
            <h4 className="text-primary">
                <NavLink to={route('app.index')}>
                    <figure className="h-20">
                        <img src={logo} alt="" className="w-full h-full object-contain"/>
                    </figure>
                </NavLink>
            </h4>
            <NavLink to={route('auth.contact')} className="font-semibold text-base">
                İletişim
            </NavLink>
        </div>
    );
}

export default Header;
