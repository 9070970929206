import * as Yup from "yup";
import {translate} from "utils/helpers";

const LoginValidation = (hasCreditCard) => {
    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .required(translate('validations.required'))
            .email(translate('validations.email')),
        password: Yup.string()
            .trim()
            .required(translate('validations.required'))
    })
}

export default LoginValidation;
