import React from 'react';

function FormSelect({options = [], ...props}) {
    return (
        <select className="form-control" {...props}>
            {options.map((i, index) => (
                <option key={index} value={i.id}>{i.name}</option>
            ))}
        </select>
    );
}

export default FormSelect;
