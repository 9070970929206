import React, {useEffect, useRef, useState} from 'react';
import {FiUpload, FiX} from "react-icons/fi";

function FormFile({onChange,  file = '', format = ['application/pdf']}) {
    const defaultText = 'Dosya seç'
    const [text, setText] = useState(defaultText);
    const [fileName, setFileName] = useState(false);
    const fileInput = useRef();

    const typeCheck = (type) => {
        return format.includes(type);
    }

    const handleChange = e => {
        const input = e.target;
        const info = input.files[0];
        if (input.files && input.files.length) {
            if (typeCheck(info.type)) {
                const reader = new FileReader();
                reader.onload = e => {
                    setFileName(info.name);
                    if (onChange) onChange(e.target.result);
                }
                reader.readAsDataURL(input.files[0])
            }
        }
    }

    const handleDelete = () => {
        setFileName(false);
        setText(defaultText);
        if (onChange) onChange('');
    }

    useEffect(() => {
        if (file) {
            setFileName(file);
        }
    }, [file])

    return (
        <div className="flex w-full items-center space-x-2 form-control">
            {!fileName && (
                <>
                    <span>
                    <FiUpload/>
                </span>
                    <label className="flex w-full cursor-pointer">
                        <input type="file" ref={fileInput} accept={format.join(',')} onChange={e => handleChange(e)} className="hidden"/>
                        <span>{text}</span>
                    </label>
                </>
            )}
            {fileName && (
                <div className="flex items-center justify-between flex-1">
                    <span className="line-clamp-1 flex pr-4">{fileName}</span>
                    <button type={'button'} onClick={() => handleDelete()}>
                        <FiX/>
                    </button>
                </div>
            )}
        </div>
    );
}

export default FormFile;
