import React from 'react';

function FormPassword(props) {
    return (
        <input
            type="password"
            className="form-control"
            {...props}
        />
    );
}

export default FormPassword;
