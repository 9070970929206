import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import Card from "components/ui/card/Card";
import {dialog, onlyNumberWrite, route, translate} from "utils/helpers";
import {Col, Row} from "antd";
import FormGroup from "components/ui/form/FormGroup";
import FormText from "components/ui/form/FormText";
import MentorRegisterValidation from "validations/mentor-register.validation";
import FormFile from "components/ui/form/FormFile";
import FormSelect from "components/ui/form/FormSelect";
import {serviceEnumSubjects, serviceEnumTrainingMethods} from "services/enum.service";
import {FiX} from "react-icons/fi";
import FormDatePicker from "components/ui/form/FormDatePicker";
import FormTextarea from "components/ui/form/FormTextarea";
import {serviceAuthRegisterMentor} from "services/auth.service";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import FormPassword from "components/ui/form/FormPassword";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function MentorRegister({recaptcha, getToken}) {

    const navigate = useNavigate();
    const accept = localStorage.getItem('mentorAccept');
    const translateKey = 'pages.register_mentor';
    const [subject, setSubject] = useState('');
    const initFetch = {
        subjects: [],
        teachingMethods: serviceEnumTrainingMethods()
    };
    const [fetchData, setFetch] = useState(initFetch);
    const {values, handleSubmit, setFieldValue, errors, touched, handleBlur, setFieldTouched} = useFormik({
        initialValues: {
            fullName: '',
            phone: '',
            email: '',
            password: '',
            recaptchaToken: '',
            website: '',
            description: '',
            files: '',
            subjects: '',
            duration: '',
            teachingMethods: [],
            startDate: '',
            quota: '',
            menteeSpecification: '',
            postMentoringSupport: '0',
            postMentoringSupportDescription: '',
        },
        validationSchema: MentorRegisterValidation,
        onSubmit: async values => {
            getToken();
            console.log(values);
            const res = await serviceAuthRegisterMentor({
                ...values,
                phone: '+90' + values.phone,
                files: values.files ? [values.files] : null,
                subjects: [values.subjects],
                startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : '',
                postMentoringSupport: values.postMentoringSupport.toString() === '1',
                duration: values.duration ? parseInt(values.duration) : '',
                recaptchaToken: recaptcha
            })
            if (res) {
                const dr = await dialog({
                    title: '',
                    message: `${translate('notification.mentorRegisterSuccess')}`,
                    buttonYes: false,
                    buttonNo: false,
                })
                if (dr) {
                    localStorage.removeItem('mentorAccept')
                    window.location.reload()
                }
            }
        }
    });

    const handleFile = e => {
        const file = e.split('base64,');
        if (file[0] && file[1] && file[0].includes('application/pdf')) {
            setFieldValue('files', {type: "PDF", base64Data: file[1]}, false)
        }
        else {
            setFieldValue('files', '', false)
        }
    }

    const fetchInit = async () => {
        const res = await serviceEnumSubjects();
        setFetch(f => ({
            ...f,
            subjects: [
                {id: '', name: translate('enum.select')},
                ...res,
                {id: 'other', name: translate('enum.other')}
            ]
        }))
    }

    useEffect(() => {
        fetchInit();
    }, [])

    if (!accept) {
        navigate(route('app.index'), {replace: true})
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row gutter={[30, 30]}>
                <Col lg={12} xs={24}>
                    <Card title={translate(translateKey + '.contact_info_title')}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.fullname')}
                                    error={errors.fullName && touched.fullName}
                                    errorMessage={errors.fullName}
                                    required={true}
                                >
                                    <FormText
                                        name="fullName"
                                        value={values.fullName}
                                        onChange={e => setFieldValue('fullName', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.phone')}
                                    error={errors.phone && touched.phone}
                                    errorMessage={errors.phone}
                                    required={true}
                                    className="!px-0"
                                >
                                    <PhoneInput
                                        inputProps={{
                                            id: 'phone'
                                        }}
                                        country={'us'}
                                        value={values.phone}
                                        onChange={phone => setFieldValue('phone', phone)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.email')}
                                    error={errors.email && touched.email}
                                    errorMessage={errors.email}
                                    required={true}
                                >
                                    <FormText
                                        name="email"
                                        value={values.email}
                                        onChange={e => setFieldValue('email', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FormGroup
                                    label={translate(translateKey + '.password')}
                                    error={errors.password && touched.password}
                                    errorMessage={errors.password}
                                    required={true}
                                    tooltip={'12 karakter içermelidir'}
                                >
                                    <FormPassword
                                        name="password"
                                        value={values.password}
                                        onChange={e => setFieldValue('password', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FormGroup
                                    label={translate(translateKey + '.rpassword')}
                                    error={errors.rpassword && touched.rpassword}
                                    errorMessage={errors.rpassword}
                                    required={true}
                                >
                                    <FormPassword
                                        name="rpassword"
                                        value={values.rpassword}
                                        onChange={e => setFieldValue('rpassword', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.website')}
                                    error={errors.website && touched.website}
                                    errorMessage={errors.website}
                                    required={false}
                                >
                                    <FormText
                                        name="website"
                                        value={values.website}
                                        onChange={e => setFieldValue('website', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.attachment')}
                                    error={errors.description && touched.description}
                                    errorMessage={errors.description}
                                    required={true}
                                    tooltip={translate(translateKey + '.attachment_tooltip')}
                                >
                                    <FormTextarea
                                        name="description"
                                        value={values.description}
                                        style={{minHeight: '90px'}}
                                        onChange={e => setFieldValue('description', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormFile
                                        name="files"
                                        value={values.files}
                                        onChange={e => handleFile(e)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={12} xs={24}>
                    <Card title={translate('pages.register_mentor.skill_info_title')}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={16}>
                                <FormGroup
                                    label={translate(translateKey + '.subjects')}
                                    error={errors.subjects && touched.subjects}
                                    errorMessage={errors.subjects}
                                    required={true}
                                    suffix={
                                        <>
                                            {subject === 'other' && (
                                                <button className="relative top-0.5 ml-2" onClick={() => {
                                                    setSubject('');
                                                    setFieldValue('subjects', [], false)
                                                }}>
                                                    <FiX/>
                                                </button>
                                            )}
                                        </>
                                    }
                                >
                                    {subject !== 'other' && (
                                        <FormSelect
                                            options={fetchData.subjects}
                                            name="subjects"
                                            value={subject}
                                            onChange={e => {
                                                setSubject(e.target.value);
                                                if (e.target.value !== 'other') {
                                                    setFieldValue('subjects', e.target.value || '', false)
                                                }
                                                else {
                                                    setFieldValue('subjects', '', false)
                                                }
                                            }}
                                            onBlur={handleBlur}
                                        />
                                    )}
                                    {subject === 'other' && (
                                        <FormText
                                            name="subjects"
                                            autoFocus={true}
                                            value={values.subjects}
                                            onChange={e => setFieldValue('subjects', e.target.value, false)}
                                            onBlur={handleBlur}
                                        />
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={24} lg={8}>
                                <FormGroup
                                    label={translate(translateKey + '.duration')}
                                    error={errors.duration && touched.duration}
                                    errorMessage={errors.duration}
                                    required={true}
                                >
                                    <FormText
                                        name="duration"
                                        value={values.duration}
                                        onChange={e => setFieldValue('duration', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.teaching_methods')}
                                    error={errors.teachingMethods && touched.teachingMethods}
                                    errorMessage={errors.teachingMethods}
                                    required={true}
                                    className="border-none divide-none !px-0 !block space-x-2 "
                                >
                                    {fetchData.teachingMethods.map((i, index) => (
                                        <label key={index} className="inline-flex items-center space-x-1">
                                            <input type="checkbox" name={'teachingMethods'} onBlur={handleBlur} className="w-4 h-4" value={i.id} onChange={e => {
                                                let check = values.teachingMethods;
                                                if (e.target.checked) {
                                                    check.push(e.target.value)
                                                }
                                                else {
                                                    check = check.filter(f => f !== e.target.value)
                                                }
                                                setFieldValue('teachingMethods', check, false)
                                            }}/>
                                            <span>{i.name}</span>
                                        </label>
                                    ))}
                                </FormGroup>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FormGroup
                                    label={translate(translateKey + '.start_date')}
                                    error={errors.startDate && touched.startDate}
                                    errorMessage={errors.startDate}
                                    required={false}
                                >
                                    <FormDatePicker
                                        name="startDate"
                                        value={values.startDate}
                                        onChange={e => setFieldValue('startDate', e, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FormGroup
                                    label={translate(translateKey + '.quota')}
                                    error={errors.quota && touched.quota}
                                    errorMessage={errors.quota}
                                    required={true}
                                >
                                    <FormText
                                        name="quota"
                                        value={values.quota}
                                        maxLength={4}
                                        onChange={e => onlyNumberWrite(e.target.value, val => setFieldValue('quota', val, false))}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.mentee_specification')}
                                    error={errors.menteeSpecification && touched.menteeSpecification}
                                    errorMessage={errors.menteeSpecification}
                                    required={true}
                                >
                                    <FormTextarea
                                        name="menteeSpecification"
                                        value={values.menteeSpecification}
                                        style={{minHeight: '90px'}}
                                        onChange={e => setFieldValue('menteeSpecification', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.post_mentoring_support')}
                                    error={errors.postMentoringSupport && touched.postMentoringSupport}
                                    errorMessage={errors.postMentoringSupport}
                                    required={true}
                                    className="border-none divide-none !px-0 "
                                >
                                    <label className="flex items-center space-x-1">
                                        <input type="radio" className="w-4 h-4" value={'1'} checked={values.postMentoringSupport === '1'} name={'postMentoringSupport'} onChange={e => setFieldValue('postMentoringSupport', e.target.value, false)}/>
                                        <span>{translate('enum.yes')}</span>
                                    </label>
                                    <label className="flex items-center space-x-1">
                                        <input type="radio" className="w-4 h-4" value={'0'} checked={values.postMentoringSupport === '0'} name={'postMentoringSupport'} onChange={e => setFieldValue('postMentoringSupport', e.target.value, false)}/>
                                        <span>{translate('enum.no')}</span>
                                    </label>
                                </FormGroup>
                            </Col>
                            {values.postMentoringSupport === '1' && (
                                <Col span={24}>
                                    <FormGroup
                                        label={translate(translateKey + '.post_mentoring_support_description')}
                                        error={errors.postMentoringSupportDescription && touched.postMentoringSupportDescription}
                                        errorMessage={errors.postMentoringSupportDescription}
                                        required={false}
                                    >
                                        <FormTextarea
                                            name="postMentoringSupportDescription"
                                            value={values.postMentoringSupportDescription}
                                            style={{minHeight: '90px'}}
                                            onChange={e => setFieldValue('postMentoringSupportDescription', e.target.value, false)}
                                            onBlur={handleBlur}
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                            <Col span={24} className="flex justify-end border-t pt-3">
                                <button type="submit" className="btn btn-primary">{translate('button.register')}</button>
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Row>
        </form>
    );
}

export default MentorRegister;
