import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {deCrypto, enCrypto} from "utils/crypto";

const authStore = createSlice({
    name: 'authStore',
    initialState: {
        token: localStorage.getItem('refresh-token') || '',
        accessToken: localStorage.getItem('accessToken') || '',
        user: localStorage.getItem('user') ? JSON.parse(deCrypto(localStorage.getItem('user'))) : {},
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
            if (localStorage.getItem('lockScreen')) {
                state.lockScreen = {}
                localStorage.removeItem('lockScreen');
            }
            localStorage.setItem('refresh-token', state.token);
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
            localStorage.setItem('accessToken', action.payload);
        },
        setUser: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('user', enCrypto(JSON.stringify(action.payload)))
        },
        logout: (state) => {
            state.token = '';
            state.accessToken = '';
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
        }
    }
})

export const {
    setToken,
    setAccessToken,
    setUser,
    lockScreen,
    logout
} = authStore.actions;

const AuthStore = authStore.reducer;

export const useAuthStore = () => useSelector(state => state.authStore);

export default AuthStore;
