import React, {useEffect, useState} from 'react';
import AdminMentorTable from "pages/admin/mentor/components/AdminMentorTable";
import AdminMentorViewModal from "pages/admin/mentor/components/AdminMentorViewModal";
import {serviceMentorFetchTable} from "services/mentor.service";
import AdminMentorStatusModal from "pages/admin/mentor/components/AdminMentorStatusModal";

function AdminMentorPage(props) {

    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [statusModalVisible, setStatusModalVisible] = useState(false);
    const [tableId, setTableId] = useState('');
    const [tableData, setTableData] = useState('');
    const [query, setQuery] = useState({
        start: 0,
        length: 10,
        orderColumn: 1,
        orderDirection: 'desc',
    });
    const [dataSource, setDataSource] = useState({
        data: [],
        total: 0,
        loading: true
    });

    const fetchTable = async () => {
        const res = await serviceMentorFetchTable(query);
        if (res) {
            setDataSource({
                data: res.data,
                total: res.recordsTotal,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchTable();
    }, [query])

    return (
        <div>
            <h3 className="font-bold text-2xl mb-4">Mentörlər</h3>
            <AdminMentorTable
                query={query}
                setQuery={setQuery}
                dataSource={dataSource}
                setTableId={setTableId}
                setTableData={setTableData}
                setViewVisible={setViewModalVisible}
                setStatusVisible={setStatusModalVisible}
            />
            {viewModalVisible && (
                <AdminMentorViewModal tableId={tableId} visible={viewModalVisible} setVisible={setViewModalVisible} />
            )}
            {statusModalVisible && (
                <AdminMentorStatusModal onReload={() => fetchTable()} tableId={tableId} tableData={tableData} visible={statusModalVisible} setVisible={setStatusModalVisible} />
            )}
        </div>
    );
}

export default AdminMentorPage;
