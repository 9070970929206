import axios from "axios";
import stores from 'store'
import {serviceLogoutAction} from "services/auth.service";
import {dialog, notification, translate} from "utils/helpers";

const service = () => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Max-Age': 86400,
        'Access-Control-Allow-Origin': '*',
    }

    const refreshToken = stores.getState().authStore.token;
    const accessToken = stores.getState().authStore.accessToken;

    const responseResolve = res => {
        return res.data;
    }

    const responseReject = err => {
        const error = err.response;
        console.log(error);
        if (error.status === 422) {
            if (typeof error.data === 'object' && Array.isArray(Object.keys(error.data))) {

            } else {
                notification({
                    type: 'error',
                    message: error.data
                });
            }
        } else if (error.status === 403 || error.status === 401) {
            serviceLogoutAction();
        } else if (error.status !== 404) {
            notification({
                type: 'error',
                message: error?.data?.message || error?.data?.exception
            })
        }

        return Promise.reject(err);
    }

    const requestResolve = async config => {
        if (refreshToken && accessToken)
            config.headers.Authorization = accessToken;
        config.headers['Content-Language'] = stores.getState().appStore.language

        if (config.method === 'delete') {
            const dr = await dialog({
                type: 'warning',
                message: translate('notification.Delete.Description')
            });

            if (dr === 'yes') return config;

            return new Promise(() => {
            });
        }

        return config;
    }

    const requestReject = err => Promise.reject(err);

    let instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 0,
        headers
    })

    instance.interceptors.response.use(
        responseResolve,
        responseReject
    )

    instance.interceptors.request.use(
        requestResolve,
        requestReject
    )

    return instance;
}

export const api = (method, url, params = {}) => {
    return service()[method](url, params);
}
