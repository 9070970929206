import React from 'react';
import {useOutletContext, useParams} from "react-router-dom";
import MenteeRegister from "pages/auth/components/MenteeRegister";
import MentorRegister from "pages/auth/components/MentorRegister";

function RegisterPage(props) {

    const [recaptcha, getToken] = useOutletContext();

    const {type} = useParams();

    return (
        <div className="">
            {/*{type === 'mentee' && <MenteeRegister recaptcha={recaptcha} getToken={getToken}/>}
            {type === 'mentor' && <MentorRegister recaptcha={recaptcha} getToken={getToken} />}*/}
            <MentorRegister recaptcha={recaptcha} getToken={getToken} />
        </div>
    );
}

export default RegisterPage;
