import cryptoJs from "crypto-js";

export const enCrypto = (text, key = 'mentorumol') => {
    return cryptoJs.AES.encrypt(text, key).toString()
}

export const deCrypto = (text, key = 'mentorumol') => {
    return cryptoJs.AES.decrypt(text, key).toString(cryptoJs.enc.Utf8)
}

