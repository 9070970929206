import React from 'react';
import {Tooltip} from "antd";
import {ImInfo} from "react-icons/im";

function FormGroup({children, required, prefix, className, suffix, label, tooltip, error, errorMessage}) {

    return (
        <div className="form-group">
            <label className="form-label">
                <span>{label} {required && <span className="text-red-500">*</span>}</span>
                {tooltip && (
                    <Tooltip title={tooltip}>
                        <span className="form-tooltip">
                            <ImInfo/>
                        </span>
                    </Tooltip>
                )}
            </label>
            <div className={`form-element ${className ? className : ''} ${error ? 'form-element-error' : ''} ${(prefix && prefix?.props?.children) ? 'prefix' : ''} ${(suffix && suffix?.props?.children) ? 'suffix' : ''}`}>
                {(prefix && prefix?.props?.children) && (
                    <div>
                        {prefix}
                    </div>
                )}
                {children}
                {(suffix && suffix?.props?.children) && (
                    <div>
                        {suffix}
                    </div>
                )}
            </div>
            {(error && errorMessage) && (
                <div className="form-error">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

export default FormGroup;
