import React, {useState} from 'react';
import Card from "components/ui/card/Card";
import LoginValidation from "validations/login.validation";
import {useFormik} from "formik";
import {useOutletContext} from "react-router-dom";
import {Col, Row} from "antd";
import FormGroup from "components/ui/form/FormGroup";
import FormText from "components/ui/form/FormText";
import FormPassword from "components/ui/form/FormPassword";
import {translate} from "utils/helpers";
import {serviceAuthLogin} from "services/auth.service";

function LoginPage() {

    const [recaptcha] = useOutletContext();
    const [loading, setLoading] = useState(false);

    const {values, handleSubmit, setFieldValue, isValid, errors, touched, handleBlur} = useFormik({
        initialValues: {
            email: '',
            password: '',
            recaptchaToken: ''
        },
        validationSchema: LoginValidation,
        onSubmit: async values => {
            setLoading(true);
            await serviceAuthLogin({...values, recaptchaToken: recaptcha});
            setLoading(false);
        }
    })

    return (
        <div className="lg:w-[300px] w-full lg:mx-auto">
            <Card>
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormGroup
                                label={'Email'}
                                error={errors.email && touched.email}
                                errorMessage={errors.email}
                                required={true}
                            >
                                <FormText
                                    name={'email'}
                                    value={values.email}
                                    onChange={e => setFieldValue('email', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={'Şifre'}
                                error={errors.password && touched.password}
                                errorMessage={errors.password}
                                required={true}
                            >
                                <FormPassword
                                    name={'password'}
                                    value={values.password}
                                    onChange={e => setFieldValue('password', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24} className="flex justify-end border-t pt-3">
                            <button type="submit" disabled={loading} className="btn btn-primary">{translate('button.login')}</button>
                        </Col>
                    </Row>
                </form>
            </Card>
        </div>
    );
}

export default LoginPage;
