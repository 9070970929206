import React, {useEffect, useState} from 'react';
import {serviceEnumCity, serviceEnumTrainingMethods} from "services/enum.service";
import {serviceMenteeFetchById, serviceMenteeFetchFile} from "services/mentee.service";
import Modal from "components/ui/modal/Modal";
import Loading from "components/ui/loading/Loading";
import {Col, Row} from "antd";
import FormGroup from "components/ui/form/FormGroup";
import FormRead from "components/ui/form/FormRead";
import {FiDownload} from "@react-icons/all-files/fi/FiDownload";
import {translate} from "utils/helpers";

function AdminMenteeViewModal({visible, setVisible, tableId}) {
    const teachingMethods = serviceEnumTrainingMethods();
    const [city, setCity] = useState({});
    const [data, setData] = useState({});

    const fetchById = async () => {
        const res = await serviceMenteeFetchById(tableId);
        if (res) {
            setData(res);
        }
    }

    const handleClose = () => {
        setVisible(false)
    }

    const handleFile = async (document) => {
        if (document) {
            const res = await serviceMenteeFetchFile(document);
        }
    }

    const getTeachingMethods = () => {
        return JSON.parse(data.teachingMethods).map(i => {
            const f = teachingMethods.find(s => s.id === i);
            return f.name || ''
        }).join(', ')
    }

    const fetchCity = async () => {
        const res = await serviceEnumCity();
        const city = res.find(i => parseInt(i.id) === data.provinceId);
        setCity(city);
    }

    useEffect(() => {
        if (tableId) {
            fetchCity();
            fetchById();
        }
    }, [tableId])


    return (
        <Modal
            title={data?.fullName}
            visible={visible}
            onClose={() => handleClose()}
            className="w-full"
        >
            <Loading loading={!data.id}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormGroup label={'İsim Soyisim'}>
                            <FormRead>{data.fullName}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'TC Kimlik no'}>
                            <FormRead>{data.idCardNumber}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Telefon'}>
                            <FormRead>{data.phone}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'E-mail'}>
                            <FormRead>{data.email}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup
                            label={'İl'}
                            prefix={
                                <button onClick={() => handleFile(data.document)}>
                                    <FiDownload/>
                                </button>
                            }
                        >
                            <FormRead>{city?.name}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup
                            label={'Meslek/ Eğitim alınan bölü'}
                        >
                            <FormRead>{data.educationDepartment}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Kendinizi yetiştirmek istediğiniz konu'}>
                            <FormRead>{data.subjects ? JSON.parse(data.subjects)[0] : ''}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Eğitimlerde kullanabileceğiniz bilgisayarınız var mı?'}>
                            <FormRead>{data.hasComputer ? 'Evet' : 'Hayır'}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Stabil bir internet bağlantınız var mı?'}>
                            <FormRead>{data.hasInternet ? 'Evet' : 'Hayır'}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Kredi kartınız var mı?'}>
                            <FormRead>{data.hasCreditCard ? 'Evet' : 'Hayır'}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Kredi kartı limitiniz'}>
                            <FormRead>{data.creditCardLimit}</FormRead>
                        </FormGroup>
                    </Col>
                </Row>
            </Loading>
        </Modal>
    );
}

export default AdminMenteeViewModal;
