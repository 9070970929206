import React from 'react';
import {serviceEnumStatuses, serviceEnumTrainingMethods} from "services/enum.service";
import {Tooltip} from "antd";
import {IoReloadOutline} from "@react-icons/all-files/io5/IoReloadOutline";
import Table from "components/ui/table/Table";

function AdminMenteeTable({query, setQuery, dataSource, setTableData, setViewVisible, setStatusVisible, setTableId}) {
    const teachingMethods = serviceEnumTrainingMethods();
    const statuses = serviceEnumStatuses();

    const columns = [
        {
            title: 'İsim Soyisim',
            dataIndex: 'fullName',
            order: 2,
            sorter: true,
            render: (value, row) => (
                <div className="cursor-pointer" onClick={() => handleViewModal(row.id)}>
                    <p>{value}</p>
                    <div className="flex flex-col">
                        <small><i>{row.email}</i></small>
                        <small><i>{row.phone}</i></small>
                    </div>
                </div>
            )
        },
        {
            title: 'TC Kimlik no',
            dataIndex: 'idCardNumber',
            sorter: true,
            order: 3,
        },
        {
            title: 'İl',
            dataIndex: 'province',
            sorter: true,
            order: 4,
        },
        {
            title: 'Meslek/ Eğitim alınan bölüm',
            dataIndex: 'educationDepartment',
            sorter: true,
            order: 5,
        },
        {
            title: 'Konular',
            dataIndex: 'subjects',
            sorter: true,
            order: 6,
            render: (value, row) => (
                <div>
                    {JSON.parse(value).join(', ')}
                </div>
            )
        },
        {
            title: 'Kayıt tarihi',
            dataIndex: 'dateCreated',
            order: 7,
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
            align: 'center',
            order: 8,
            render: value => {
                const f = statuses.find(i => i.id === value);
                return f.name ? <span className={`status ${f.className}`}>{f.name}</span> : '-'
            }
        },
    ];

    const actionRender = (row) => {
        return (
            <>
                {row.status === 'NEW' && (
                    <Tooltip title={'Statusu güncelle'}>
                        <button onClick={() => handleChangeStatus(row)}>
                            <IoReloadOutline/>
                        </button>
                    </Tooltip>
                )}
            </>
        )
    }

    const handleViewModal = (id) => {
        setTableId(id);
        setViewVisible(true);
    }

    const handleChangeStatus = data => {
        setTableData(data);
        setStatusVisible(true);
    }

    return (
        <Table
            tableQuery={query}
            setTableQuery={setQuery}
            dataSource={dataSource.data}
            total={dataSource.total}
            columns={columns}
            loading={dataSource.loading}
            sortingStart={1}
            store={true}
            actionWidth={40}
            actionButton={row => actionRender(row)}
        />
    );
}

export default AdminMenteeTable;
