import React, {useEffect, useState} from 'react';
import {serviceMenteeFetchTable} from "services/mentee.service";
import AdminMenteeTable from "pages/admin/mentee/components/AdminMenteeTable";
import AdminMenteeViewModal from "pages/admin/mentee/components/AdminMenteeViewModal";
import AdminMenteeStatusModal from "pages/admin/mentee/components/AdminMenteeStatusModal";

function AdminMenteePage(props) {
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [statusModalVisible, setStatusModalVisible] = useState(false);
    const [tableId, setTableId] = useState('');
    const [tableData, setTableData] = useState('');
    const [query, setQuery] = useState({
        start: 0,
        length: 10,
        orderColumn: 1,
        orderDirection: 'desc',
    });
    const [dataSource, setDataSource] = useState({
        data: [],
        total: 0,
        loading: true
    });

    const fetchTable = async () => {
        const res = await serviceMenteeFetchTable(query);
        if (res) {
            setDataSource({
                data: res.data,
                total: res.recordsTotal,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchTable();
    }, [query])

    return (
        <div>
            <h3 className="font-bold text-2xl mb-4">Öğrenciler</h3>
            <AdminMenteeTable
                query={query}
                setQuery={setQuery}
                dataSource={dataSource}
                setTableId={setTableId}
                setTableData={setTableData}
                setViewVisible={setViewModalVisible}
                setStatusVisible={setStatusModalVisible}
            />
            {viewModalVisible && (
                <AdminMenteeViewModal tableId={tableId} visible={viewModalVisible} setVisible={setViewModalVisible} />
            )}
            {statusModalVisible && (
                <AdminMenteeStatusModal onReload={() => fetchTable()} tableId={tableId} tableData={tableData} visible={statusModalVisible} setVisible={setStatusModalVisible} />
            )}
        </div>
    );
}

export default AdminMenteePage;
