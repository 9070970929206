import React from 'react';
import {Outlet} from "react-router-dom";
import Header from "components/common/user/Header";

function UserLayout(props) {
    return (
        <div className="container mx-auto py-2">
            <Header/>
            <Outlet/>
        </div>
    );
}

export default UserLayout;
