import React, {useEffect, useState} from 'react';
import FormGroup from "components/ui/form/FormGroup";
import FormText from "components/ui/form/FormText";
import {Col, Row} from "antd";
import {useFormik} from "formik";
import {serviceAuthRegisterMentee, serviceAuthRegisterMentor} from "services/auth.service";
import FormSelect from "components/ui/form/FormSelect";
import {serviceEnumCity, serviceEnumSubjects} from "services/enum.service";
import FormFile from "components/ui/form/FormFile";
import {dialog, onlyNumberWrite, route, translate} from "utils/helpers";
import {useNavigate} from "react-router-dom";
import Card from "components/ui/card/Card";
import FormTextarea from "components/ui/form/FormTextarea";
import {FiX} from "react-icons/fi";
import MenteeRegisterValidation from "validations/mentee-register.validation";
import FormPassword from "components/ui/form/FormPassword";

function MenteeRegister({recaptcha, getToken}) {
    const navigate = useNavigate();
    const accept = localStorage.getItem('mentorAccept');
    const translateKey = 'pages.register_mentee';
    const [subject, setSubject] = useState('');
    const [hasCreditCard, setHasCreditCard] = useState('');
    const initFetch = {
        subjects: [],
        cities: [],
        creditCardLimits: [
            {id: '', name: translate('enum.select')},
            {id: '0-5000', name: '0-5000 TL'},
            {id: '5000-10.000', name: '5000-10.000 TL'},
            {id: '+10.000', name: '10.000 TL ve üzeri'}
        ]
    };
    const [fetchData, setFetch] = useState(initFetch);
    const {values, handleSubmit, setFieldValue, errors, touched, handleBlur, setFieldTouched} = useFormik({
        initialValues: {
            fullName: '',
            phone: '',
            email: '',
            password: '',
            recaptchaToken: '',
            idCardNumber: '',
            provinceId: '',
            document: '',
            subjects: '',
            educationDepartment: '',
            hasComputer: '',
            hasInternet: '',
            hasCreditCard: '',
            creditCardLimit: '',
        },
        validationSchema: MenteeRegisterValidation(hasCreditCard),
        onSubmit: async values => {
            getToken();
            console.log(values);
            const res = await serviceAuthRegisterMentee({
                ...values,
                phone: '+90' + values.phone,
                subjects: [values.subjects],
                hasComputer: values.hasComputer === '1',
                hasInternet: values.hasInternet === '1',
                hasCreditCard: values.hasCreditCard === '1',
                creditCardLimit: values.creditCardLimit ? values.creditCardLimit : 0,
                recaptchaToken: recaptcha
            })
            if (res) {
                const dr = await dialog({
                    title: '',
                    message: `${translate('notification.menteeRegisterSuccess')}`,
                    buttonYes: false,
                    buttonNo: false,
                })
                if (dr) {
                    localStorage.removeItem('mentorAccept')
                    window.location.reload()
                }
            }
        }
    });

    const handleFile = e => {
        const file = e.split('base64,');
        if (file[0] && file[1] && file[0].includes('application/pdf')) {
            setFieldValue('document', {type: "PDF", base64Data: file[1]}, false)
        }
        else {
            setFieldValue('document', '', false)
        }
    }

    const fetchInit = async () => {
        const res = await serviceEnumSubjects();
        const cities = await serviceEnumCity();
        setFetch(f => ({
            ...f,
            cities: [
                {id: '', name: translate('enum.select')},
                ...cities
            ],
            subjects: [
                {id: '', name: translate('enum.select')},
                ...res,
                {id: 'other', name: translate('enum.other')}
            ]
        }))
    }

    useEffect(() => {
        fetchInit();
    }, [])

    if (!accept) {
        navigate(route('app.index'), {replace: true})
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="lg:w-[400px] w-full lg:mx-auto">
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.fullname')}
                                error={errors.fullName && touched.fullName}
                                errorMessage={errors.fullName}
                                required={true}
                            >
                                <FormText
                                    name="fullName"
                                    value={values.fullName}
                                    onChange={e => setFieldValue('fullName', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.idCardNumber')}
                                error={errors.idCardNumber && touched.idCardNumber}
                                errorMessage={errors.idCardNumber}
                                required={true}
                            >
                                <FormText
                                    name="idCardNumber"
                                    value={values.idCardNumber}
                                    maxLength={11}
                                    onChange={e => onlyNumberWrite(e.target.value, val => setFieldValue('idCardNumber', val, false))}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.phone')}
                                error={errors.phone && touched.phone}
                                errorMessage={errors.phone}
                                required={true}
                                prefix={<span>+90</span>}
                            >
                                <FormText
                                    name="phone"
                                    value={values.phone}
                                    maxLength={10}
                                    onChange={e => onlyNumberWrite(e.target.value, val => setFieldValue('phone', val, false))}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.email')}
                                error={errors.email && touched.email}
                                errorMessage={errors.email}
                                required={true}
                            >
                                <FormText
                                    name="email"
                                    value={values.email}
                                    onChange={e => setFieldValue('email', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={24} lg={12}>
                            <FormGroup
                                label={translate(translateKey + '.password')}
                                error={errors.password && touched.password}
                                errorMessage={errors.password}
                                required={true}
                            >
                                <FormPassword
                                    name="password"
                                    value={values.password}
                                    autoComplete={'new-name'}
                                    onChange={e => setFieldValue('password', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={24} lg={12}>
                            <FormGroup
                                label={translate(translateKey + '.rpassword')}
                                error={errors.rpassword && touched.rpassword}
                                errorMessage={errors.rpassword}
                                required={true}
                            >
                                <FormPassword
                                    name="rpassword"
                                    value={values.rpassword}
                                    onChange={e => setFieldValue('rpassword', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.provinceId')}
                                error={errors.provinceId && touched.provinceId}
                                errorMessage={errors.provinceId}
                                required={false}
                            >
                                <FormSelect
                                    options={fetchData.cities}
                                    name="provinceId"
                                    value={values.provinceId}
                                    onChange={e => setFieldValue('provinceId', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.document')}
                                tooltip={translate(translateKey + '.document_tooltip')}
                                error={errors.document && touched.document}
                                errorMessage={errors.document}
                                required={true}
                            >
                                <FormFile
                                    name="document"
                                    value={values.document}
                                    onChange={e => handleFile(e)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.educationDepartment')}
                                error={errors.educationDepartment && touched.educationDepartment}
                                errorMessage={errors.educationDepartment}
                                required={true}
                            >
                                <FormTextarea
                                    name="educationDepartment"
                                    value={values.educationDepartment}
                                    style={{minHeight: '90px'}}
                                    onChange={e => setFieldValue('educationDepartment', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.subjects')}
                                error={errors.subjects && touched.subjects}
                                errorMessage={errors.subjects}
                                required={true}
                                tooltip={translate(translateKey + '.subjects_tooltip')}
                                suffix={
                                    <>
                                        {subject === 'other' && (
                                            <button className="relative top-0.5 ml-2" onClick={() => {
                                                setSubject('');
                                                setFieldValue('subjects', [], false)
                                            }}>
                                                <FiX/>
                                            </button>
                                        )}
                                    </>
                                }
                            >
                                {subject !== 'other' && (
                                    <FormSelect
                                        options={fetchData.subjects}
                                        name="subjects"
                                        value={subject}
                                        onChange={e => {
                                            setSubject(e.target.value);
                                            if (e.target.value !== 'other') {
                                                setFieldValue('subjects', e.target.value || '', false)
                                            }
                                            else {
                                                setFieldValue('subjects', '', false)
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                )}
                                {subject === 'other' && (
                                    <FormText
                                        name="subjects"
                                        autoFocus={true}
                                        value={values.subjects}
                                        onChange={e => setFieldValue('subjects', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                )}
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.hasComputer')}
                                error={errors.hasComputer && touched.hasComputer}
                                errorMessage={errors.hasComputer}
                                required={true}
                                className="border-none divide-none !px-0 "
                            >
                                <label className="flex items-center space-x-1">
                                    <input type="radio" className="w-4 h-4" value={'1'} checked={values.hasComputer === '1'} name={'hasComputer'} onChange={e => setFieldValue('hasComputer', e.target.value, false)}/>
                                    <span>{translate('enum.yes')}</span>
                                </label>
                                <label className="flex items-center space-x-1">
                                    <input type="radio" className="w-4 h-4" value={'0'} checked={values.hasComputer === '0'} name={'hasComputer'} onChange={e => setFieldValue('hasComputer', e.target.value, false)}/>
                                    <span>{translate('enum.no')}</span>
                                </label>
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.hasInternet')}
                                error={errors.hasInternet && touched.hasInternet}
                                errorMessage={errors.hasInternet}
                                required={true}
                                className="border-none divide-none !px-0 "
                            >
                                <label className="flex items-center space-x-1">
                                    <input type="radio" className="w-4 h-4" value={'1'} checked={values.hasInternet === '1'} name={'hasInternet'} onChange={e => setFieldValue('hasInternet', e.target.value, false)}/>
                                    <span>{translate('enum.yes')}</span>
                                </label>
                                <label className="flex items-center space-x-1">
                                    <input type="radio" className="w-4 h-4" value={'0'} checked={values.hasInternet === '0'} name={'hasInternet'} onChange={e => setFieldValue('hasInternet', e.target.value, false)}/>
                                    <span>{translate('enum.no')}</span>
                                </label>
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.hasCreditCard')}
                                error={errors.hasCreditCard && touched.hasCreditCard}
                                errorMessage={errors.hasCreditCard}
                                required={true}
                                className="border-none divide-none !px-0 "
                                tooltip={translate(translateKey + '.hasCreditCard_tooltip')}
                            >
                                <label className="flex items-center space-x-1">
                                    <input type="radio" className="w-4 h-4" value={'1'} checked={values.hasCreditCard === '1'} name={'hasCreditCard'} onChange={e => setFieldValue('hasCreditCard', e.target.value, false)}/>
                                    <span>{translate('enum.yes')}</span>
                                </label>
                                <label className="flex items-center space-x-1">
                                    <input type="radio" className="w-4 h-4" value={'0'} checked={values.hasCreditCard === '0'} name={'hasCreditCard'} onChange={e => setFieldValue('hasCreditCard', e.target.value, false)}/>
                                    <span>{translate('enum.no')}</span>
                                </label>
                            </FormGroup>
                        </Col>
                        {values.hasCreditCard === '1' && (
                            <Col span={24}>
                                <FormGroup
                                    label={translate(translateKey + '.creditCardLimit')}
                                    error={errors.creditCardLimit && touched.creditCardLimit}
                                    errorMessage={errors.creditCardLimit}
                                    required={false}
                                >
                                    <FormSelect
                                        options={fetchData.creditCardLimits}
                                        name="creditCardLimit"
                                        value={values.creditCardLimit}
                                        onChange={e => setFieldValue('creditCardLimit', e.target.value, false)}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>
                        )}
                        <Col span={24} className="flex justify-end border-t pt-3">
                            <button type="submit" className="btn btn-primary">{translate('button.register')}</button>
                        </Col>
                    </Row>
                </Card>
            </div>
        </form>
    );
}

export default MenteeRegister;
