import React from 'react';

function FormRead({children}) {
    return (
        <div
            className="form-control flex items-center"
        >
            {children}
        </div>
    );
}

export default FormRead;
