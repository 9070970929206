import routers from "router/routers";
import Swal from 'sweetalert2'
import {notification as AntdNotification} from "antd";
import {generatePath} from "react-router-dom";
import store from 'store'

export const translate = (key = null, params = {}) => {

    const permission = store.getState().authStore.permission;

    const getDescendantProp = (obj, desc) => {
        let arr = desc.split(".");
        while (arr.length && (obj = obj[arr.shift()])) ;
        return obj;
    }

    let languages = {}

    if (permission === 'admin') {
        languages = {
            tr: require('locales/admin/tr.json'),
        }
    } else {
        languages = {
            tr: require('locales/user/tr.json'),
        }
    }

    const result = params.result ? params.result : 'yes',
        search = params.search ? params.search : null,
        replace = params.replace ? params.replace : null,
        label = params.label ? params.label : null;

    let lang = '';
    if (params.lang) lang = params.lang;
    else if (store.getState().authStore.user?.language?.code) lang = store.getState().authStore.user?.language?.code;
    else lang = store.getState().appStore.language;

    if (lang) {
        let translates = languages[lang] || languages['en'];
        if (translates && Object.keys(translates).length > 0) {
            if (key) {
                let text = getDescendantProp(translates, key);
                if (search && replace) {
                    if (Array.isArray(search) && Array.isArray(replace)) return text ? this.replaceAll(text, search, replace) : key;
                    else return text ? text.replace(search, replace) : key;
                } else if (label) return getDescendantProp(translates, key).replace(':label', this.translate(label).toLowerCase());
                return text || key;
            }
        }
    }
    if (result === 'yes') return key;
    return ''
}

export const route = (name, params = {}) => {
    let lastRoute, path = '';
    if (name) {
        name.split('.').map(n => {
            if (!lastRoute) {
                lastRoute = routers.find(i => i.name === n);
                if (lastRoute)
                    path = lastRoute?.path;
            } else {
                lastRoute = lastRoute.children.find(i => i.name === n);
                if (lastRoute && lastRoute?.path) {
                    path += '/' + lastRoute?.path;
                }
            }
        });
        if (path) {
            path = path.replace(/\/\//gi, '/');
            return Object.values(params).length ? generatePath(path, params) : path;
        }
    }
    return '/';
}

export const onlyNumberWrite = (value, callback, float = false) => {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
        if (float) {
            return callback(value ? value : '');
        }
        return callback(value ? parseFloat(value) : '');
    }
}

export const dialog = (params = {}) => {
    const title = params.title || '';
    const html = params.message || null;
    const icon = params.icon || 'info';
    const iconColor = params.iconColor || '';
    const iconHtml = params.iconHtml || '';
    const confirmButtonText = params.buttonYesText || translate('button.yes');
    const cancelButtonColor = params.buttonNoColor || '#b91c1c';
    const denyButtonColor = params.buttonDenyColor || '#888';
    const cancelButtonText = params.buttonNoText || translate('button.no');
    const denyButtonText = params.buttonDenyText || '';
    const showCancelButton = params.buttonNo !== false;
    const showConfirmButton = params.buttonYes !== false;
    const showCloseButton = params.buttonClose || false;
    const showDenyButton = params.buttonDeny || false;
    const customClass = params.customClass || {};

    return new Promise((resolve, reject) => {
        Swal.fire({
            customClass,
            icon,
            iconColor,
            iconHtml,
            title,
            html,
            confirmButtonText,
            cancelButtonColor,
            cancelButtonText,
            showConfirmButton,
            showCloseButton,
            showCancelButton,
            showDenyButton,
            denyButtonText,
            denyButtonColor,
        })
            .then(r => {
                if (r.value) {
                    resolve('yes');
                } else if (r.dismiss === 'backdrop') {
                    resolve('close');
                } else if (r.dismiss === 'cancel') {
                    resolve('no');
                } else if (r.isDenied) {
                    resolve('deny');
                }
            })
    })
}

export const notification = (params = {}) => {
    const placement = params.placement || 'topRight';
    const type = params.type || 'success';
    const onClose = params?.onClose ? params.onClose() : false;
    const duration = params?.duration ? params.duration : 4.5;

    AntdNotification[type]({
        message: params?.title || translate('notification.alert'),
        description: params.message,
        placement,
        duration,
        onClose: () => {
            return onClose;
        }
    })
}
