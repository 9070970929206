import * as Yup from "yup";
import {translate} from "utils/helpers";

const passwordRegex = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_=+{}\\[\]\\|;:'",<.>\\/?-])[A-Za-z\d!@#$%^&*()_=+{}\\[\]\\|;:'",<.>\\/?-]{12,}$/gm);

const MentorRegisterValidation = () => {
    return Yup.object().shape({
        email: Yup.string()
            .required(translate('validations.required'))
            .email(translate('validations.email')),
        fullName: Yup.string()
            .required(translate('validations.required')),
        password: Yup.string()
            .min(12, translate('validations.min', {search: ':min', replace: 12}))
            .required(translate('validations.required')),
        rpassword: Yup.string()
            .required(translate('validations.required'))
            .oneOf([Yup.ref('password'), null], translate('validations.passwordNotSome')),
        phone: Yup.string()
            .required(translate('validations.required')),
        description: Yup.string()
            .required(translate('validations.required')),
        subjects: Yup.string()
            .required(translate('validations.required')),
        duration: Yup.string()
            .required(translate('validations.required')),
        teachingMethods: Yup.array()
            .min(1, translate('validations.required')),
        quota: Yup.string()
            .required(translate('validations.required')),
        menteeSpecification: Yup.string()
            .required(translate('validations.required')),
    });
}

export default MentorRegisterValidation;
