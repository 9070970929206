import React, {useState} from 'react';
import {useOutletContext} from "react-router-dom";
import {useFormik} from "formik";
import {serviceAuthContact} from "services/auth.service";
import Card from "components/ui/card/Card";
import {Col, Row} from "antd";
import FormGroup from "components/ui/form/FormGroup";
import FormText from "components/ui/form/FormText";
import {dialog, translate} from "utils/helpers";
import FormTextarea from "components/ui/form/FormTextarea";
import ContactValidation from "validations/contact.validation";

function ContactPage(props) {
    const [recaptcha] = useOutletContext();
    const [loading, setLoading] = useState(false);

    const {values, handleSubmit, setFieldValue, resetForm, errors, touched, handleBlur} = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            message: '',
            recaptchaToken: ''
        },
        validationSchema: ContactValidation,
        onSubmit: async values => {
            setLoading(true);
            const res = await serviceAuthContact({...values, recaptchaToken: recaptcha});
            if (res) {
                const dr = await dialog({
                    type: 'success',
                    message: 'Mesajınız bize ulaşmıştır. Teşekkür ederiz.',
                    buttonYes: false,
                    buttonNo: false
                })
                if (dr) {
                    resetForm()
                }
            }
            setLoading(false);
        }
    })

    return (
        <div className="lg:w-[600px] w-full lg:mx-auto">
            <Card>
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col lg={12} xs={24}>
                            <FormGroup
                                label={'İsim Soyisim'}
                                error={errors.fullName && touched.fullName}
                                errorMessage={errors.fullName}
                                required={true}
                            >
                                <FormText
                                    name={'fullName'}
                                    value={values.fullName}
                                    onChange={e => setFieldValue('fullName', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={12} xs={24}>
                            <FormGroup
                                label={'Email'}
                                error={errors.email && touched.email}
                                errorMessage={errors.email}
                                required={true}
                            >
                                <FormText
                                    name={'email'}
                                    value={values.email}
                                    onChange={e => setFieldValue('email', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={'Mesaj'}
                                error={errors.message && touched.message}
                                errorMessage={errors.message}
                                required={true}
                            >
                                <FormTextarea
                                    rows={10}
                                    name={'message'}
                                    value={values.message}
                                    onChange={e => setFieldValue('message', e.target.value, false)}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24} className="flex justify-end border-t pt-3">
                            <button type="submit" disabled={loading} className="btn btn-primary">{translate('button.send')}</button>
                        </Col>
                    </Row>
                </form>
            </Card>
        </div>
    );
}

export default ContactPage;
