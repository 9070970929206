import {api} from "utils/api";
import MentorApi from "api/mentor.api";
import {notification} from "utils/helpers";
import axios from "axios";
import stores from "store";

export const serviceMentorFetchTable = async (params) => {
    const res = await api('get', MentorApi.fetchTable, {params});
    if (res.code === 200) {
        return res;
    }
}

export const serviceMentorFetchById = async id => {
    const res = await api('get', MentorApi.fetchById.replace(':id', id));
    if (res.code === 200) {
        return res.mentor;
    }
}

export const serviceMentorFetchFile = async path => {
    const res = await axios.get(process.env.REACT_APP_API_URL + MentorApi.fetchFile, {
        params: {path},
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: stores.getState().authStore.accessToken
        },
        responseType: 'blob'
    })
    const blob = new Blob([res.data], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url)
}


export const serviceMentorPutStatus = async values => {
    const res = await api('put', MentorApi.putStatus, values);
    if (res.code === 200) {
        return true;
    }
    else if (res.code === 405) {
        notification({
            type: 'warning',
            message: 'Durum zaten güncellendi'
        })
    }
    return false;
}
