import React from 'react';
import {NavLink, useLocation} from "react-router-dom";
import {route} from "utils/helpers";
import {serviceLogoutAction} from "services/auth.service";
import logo from 'assets/img/logo.svg'

function Header(props) {

    const {pathname} = useLocation();

    return (
        <div className="bg-white shadow py-3 px-5 rounded flex items-center justify-between mb-5">
            <h4 className="text-primary">
                <NavLink to={route('app.index')}>
                    <figure className="h-20">
                        <img src={logo} alt="" className="w-full h-full object-contain"/>
                    </figure>
                </NavLink>
            </h4>
            <div className="space-x-4">
                <NavLink to={'/admin'} className={`font-medium px-2 ${pathname === '/admin' ? 'text-primary' : ''}`}>Öğrenciler</NavLink>
                <NavLink to={'/admin/mentor'} className={`font-medium px-2 ${pathname === '/admin/mentor' ? 'text-primary' : ''}`}>Mentörler</NavLink>
                <button className="ml-5 pl-5 border-l" onClick={() => serviceLogoutAction()}>
                    Çıkış yap
                </button>
            </div>
        </div>
    );
}

export default Header;
