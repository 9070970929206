import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import Header from "components/common/admin/Header";
import {useAuthStore} from "store/module/auth.store";
import {route} from "utils/helpers";

function AdminLayout(props) {

    const {token} = useAuthStore();

    if (!token) {
        return <Navigate to={route('auth.login')} replace={true}/>
    }

    return (
        <div className="container mx-auto py-2">
            <Header/>
            <Outlet/>
        </div>
    );
}

export default AdminLayout;
