import React from 'react';
import Table from "components/ui/table/Table";
import {IoReloadOutline} from "@react-icons/all-files/io5/IoReloadOutline";
import {serviceEnumStatuses, serviceEnumTrainingMethods} from "services/enum.service";
import {Tooltip} from "antd";

function AdminMentorTable({query, setQuery, dataSource, setTableData, setViewVisible, setStatusVisible, setTableId}) {

    const teachingMethods = serviceEnumTrainingMethods();
    const statuses = serviceEnumStatuses();

    const columns = [
        {
            title: 'İsim Soyisim',
            dataIndex: 'fullName',
            sorter: true,
            order: 2,
            render: (value, row) => (
                <div className="cursor-pointer" onClick={() => handleViewModal(row.id)}>
                    <p>{value}</p>
                    <div className="flex flex-col">
                        <small><i>{row.email}</i></small>
                        <small><i>{row.phone}</i></small>
                    </div>
                </div>
            )
        },
        {
            title: 'Konular',
            dataIndex: 'subjects',
            sorter: true,
            order: 3,
            render: (value, row) => (
                <div>
                    {JSON.parse(value).join(', ')}
                </div>
            )
        },
        {
            title: 'Süresi',
            dataIndex: 'duration',
            sorter: true,
            order: 4,
            render: value => value + ' saat'
        },
        {
            title: 'Eğitim türü',
            dataIndex: 'teachingMethods',
            sorter: true,
            order: 5,
            render: (value, row) => (
                <div>
                    {JSON.parse(value).map((d, index) => {
                        const f = teachingMethods.find(i => i.id === d);
                        return <p key={index}>{f.name}</p>;
                    })}
                </div>
            )
        },
        {
            title: 'Başlama tarihi',
            dataIndex: 'startDate',
            order: 6,
            sorter: true
        },
        {
            title: 'Kontenjan',
            dataIndex: 'quota',
            order: 7,
            sorter: true,
        },
        {
            title: 'Kayıt tarihi',
            dataIndex: 'dateCreated',
            sorter: true,
            order: 8,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
            order: 9,
            align: 'center',
            render: value => {
                const f = statuses.find(i => i.id === value);
                return f.name ? <span className={`status ${f.className}`}>{f.name}</span> : '-'
            }
        },
    ];

    const actionRender = (row) => {
        return (
            <>
                {row.status === 'NEW' && (
                    <Tooltip title={'Statusu güncelle'}>
                        <button onClick={() => handleChangeStatus(row)}>
                            <IoReloadOutline/>
                        </button>
                    </Tooltip>
                )}
            </>
        )
    }

    const handleViewModal = (id) => {
        setTableId(id);
        setViewVisible(true);
    }

    const handleChangeStatus = data => {
        setTableData(data);
        setStatusVisible(true);
    }

    return (
        <Table
            tableQuery={query}
            setTableQuery={setQuery}
            dataSource={dataSource.data}
            total={dataSource.total}
            columns={columns}
            loading={dataSource.loading}
            sortingStart={1}
            store={true}
            actionWidth={40}
            actionButton={row => actionRender(row)}
        />
    );
}

export default AdminMentorTable;
