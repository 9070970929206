import {api} from "utils/api";
import EnumApi from "api/enum.api";
import {translate} from "utils/helpers";

export const serviceEnumCity = async () => {
    const res = await api('get', EnumApi.fetchCities);
    if (res.code === 200) {
        return res.provinces;
    }
}


export const serviceEnumSubjects = async () => {
    const res = await api('get', EnumApi.fetchSubjects);
    if (res.code === 200) {
        return res.subjects;
    }
}

export const serviceEnumTrainingMethods = () => {
    return [
        {id: 'LIVE_BROADCAST', name: 'Canlı Yayın'},
        {id: 'VIDEO_TRAINING', name: 'Eğitim Videosu'},
        {id: 'ONE_TO_ONE', name: 'Birebir Bağlantı '},
    ]
}

export const serviceEnumStatuses = () => {
    return [
        {id: '', name: translate('enum.select'), selectBox: true, className: ''},
        {id: 'NEW', name: 'Mail doğrulama bekliyor', selectBox: false, className: 'status-yellow-full'},
        {id: 'ACTIVE', name: 'Aktif', selectBox: true, className: 'status-green-full'},
        {id: 'REJECTED', name: 'Kabul edilmedi', selectBox: true, className: 'status-red-full'},
        {id: 'UNVERIFIED', name: 'Onaylama Bekliyor', selectBox: false, className: 'status-gray-full'},
    ]
}
