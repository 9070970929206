import "assets/css/index.css"
import React from 'react';
import ReactDOM from 'react-dom/client';
import routers from "router/routers";
import store from 'store'
import {ConfigProvider} from "antd";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import 'moment/locale/az'
import 'moment/locale/tr'
import 'moment/locale/ru'
import moment from "moment";
import {Provider} from "react-redux";
import {antdLanguage} from "services/app.service";

moment.locale(store.getState().appStore.language);

const consoleError = console.error.bind(console);
console.error = (errObj, ...args) => {
    if ((typeof errObj === 'string' && args.includes('findDOMNode'))) return;
    else if ((typeof errObj === 'string' && errObj.includes('(`%s`). To locate the bad setState() call inside `%s`'))) return;
    consoleError(errObj, ...args);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ConfigProvider locale={antdLanguage()}>
            <RouterProvider router={createBrowserRouter(routers)}/>
        </ConfigProvider>
    </Provider>
);
