import React from 'react';

function FormText(props) {
    return (
        <input
            type="text"
            className="form-control"
            {...props}
        />
    );
}

export default FormText;
