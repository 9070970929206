import React, {useEffect, useRef, useState} from 'react';
import {Navigate, Outlet, useLocation, useParams} from "react-router-dom";
import Header from "components/common/user/Header";
import ReCAPTCHA from "react-google-recaptcha";
import {useAuthStore} from "store/module/auth.store";

function AuthLayout(props) {
    const {pathname} = useLocation();
    const {token} = useAuthStore();

    if (pathname === '/auth') {
        return <Navigate to={'/auth/login'} replace={true} />
    }

    const reRef = useRef();
    const [recaptcha, setRecaptcha] = useState('');

    const getToken = async () => {
        if (reRef.current) {
            await reRef.current.reset();
            const token = await reRef?.current?.executeAsync();
            setRecaptcha(token);
            return token;
        }
    }

    useEffect(() => {
        getToken();
        return () => {
            if (reRef.current)
                reRef.current.reset();
        }
    }, [reRef])

    if (token) {
        return <Navigate to={'/admin'} replace={true}/>
    }

    return (
        <div className="container mx-auto py-2">
            <Header/>
            <div className="lg:w-[950px] w-full mx-auto mb-10">
                <Outlet context={[recaptcha, getToken]}/>
            </div>

            <div className="hidden">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size={'invisible'}
                    ref={reRef}
                />
            </div>
        </div>
    );
}

export default AuthLayout;
