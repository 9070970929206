import React, {useEffect} from 'react';
import image from 'assets/img/image-1.svg'
import {dialog, route, translate} from "utils/helpers";
import {NavLink, useNavigate} from "react-router-dom";

function HomePage(props) {

    const navigate = useNavigate();

    const handleButton = async type => {
        const dr = await dialog({
            message: translate(`notification.${type}RegisterWelcome`)
        })
        if (dr === 'yes') {
            localStorage.setItem('mentorAccept', true)
            navigate(route('auth.register', {type}), {replace: true})
        }
    }

    const handleMenteeButton = () => {
        dialog({
            message: translate(`notification.menteeRegisterAlert`),
            buttonYes: false,
            buttonNo: false,
        })
    }

    useEffect(() => {
        localStorage.removeItem('mentorAccept')
    }, [])

    return (
        <div>
            <div className="lg:grid lg:grid-cols-12 gap-5 lg:space-y-0 space-y-2">
                <div className="prose max-w-full prose-sm bg-white rounded shadow p-4 col-span-8">
                    <h2 className="text-center">BİLGİLENDİRME</h2>
                    <p>
                        Mentorumol.org platformunun amacı, ülkemizin yaşadığı elim afet sonrası, işini kaybetmiş ya da gelir
                        ihtiyacı olan depremzedelere, lokasyondan bağımsız gelir elde etmenin hiç olmadığı kadar önemli olduğu
                        bu dönemde destek olmaktır.
                    </p>
                    <p>
                        Mentorumol.org platformunun, bu süreçte üzerine aldığı sorumluluk mentör ve mentörlük almak isteyen
                        kişileri buluşturmak, profesyonelleşmek istedikleri konularda ücretsiz mentör bulmaları konusunda
                        aracılık sağlamaktır.
                    </p>
                    <p>
                        Mentörler ve mentörlük almak isteyenlerin doldurduğu formlar, ekibimiz tarafında titizlikle ve hızla
                        incelenip eşleştirme sistemi ile değerlendirilecektir. Değerlendirme sonrası, mentörlük almak
                        isteyenlerin bilgileri, mentörlere iletilecektir. Mentörlük süreci ile ilgili iletişim, mentörler
                        tarafından sağlanacak olup, verilecek olan mentörlük eğitimin kapsamı yine mentör tarafından
                        belirlenecektir.
                    </p>
                    <p>
                        Mentorumol.org platformu, bu aracılık hizmetinden kar amacı gütmez, kar etmez.
                    </p>
                </div>
                <div className="card col-span-4">
                    <img src={image} alt="" className="w-full h-full object-cover"/>
                </div>
            </div>
            <div className="card mt-5 flex justify-center space-x-4">
                <button onClick={() => handleMenteeButton()} className="btn btn-primary">Mentör bul</button>
                <button onClick={() => handleButton('mentor')} className="btn btn-primary">Mentör ol</button>
            </div>
        </div>
    );
}

export default HomePage;
