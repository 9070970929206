import HomePage from "pages/home/HomePage";
import UserLayout from "layouts/UserLayout";
import AuthLayout from "layouts/AuthLayout";
import LoginPage from "pages/auth/LoginPage";
import RegisterPage from "pages/auth/RegisterPage";
import {Navigate} from "react-router-dom";
import AdminLayout from "layouts/AdminLayout";
import AdminMenteePage from "pages/admin/mentee/AdminMenteePage";
import AdminMentorPage from "pages/admin/mentor/AdminMentorPage";
import ContactPage from "pages/contact/ContactPage";

export default [
    {
        path: '/',
        element: <UserLayout/>,
        name: 'app',
        children: [
            {
                index: true,
                name: 'index',
                element: <HomePage/>
            }
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        element: <AdminLayout/>,
        children: [
            {
                index: true,
                name: 'mentee',
                element: <AdminMenteePage/>
            },
            {
                path: 'mentor',
                name: 'mentor',
                element: <AdminMentorPage/>
            },
        ]
    },
    {
        path: '/auth',
        name: 'auth',
        element: <AuthLayout/>,
        children: [
            {
                path: 'login',
                name: 'login',
                element: <LoginPage/>
            },
            {
                path: 'register/:type',
                name: 'register',
                element: <RegisterPage/>
            },
            {
                name: 'contact',
                path: 'contact',
                element: <ContactPage/>
            }
        ]
    },
    {
        path: '*',
        element: <Navigate to={'/'} replace={true} />
    }
]
