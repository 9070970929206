import * as Yup from "yup";
import {translate} from "utils/helpers";

const ContactValidation = () => {
    return Yup.object().shape({
        fullName: Yup.string()
            .trim()
            .required(translate('validations.required')),
        email: Yup.string()
            .trim()
            .required(translate('validations.required'))
            .email(translate('validations.email')),
        message: Yup.string()
            .trim()
            .required(translate('validations.required'))
    })
}

export default ContactValidation;
