import stores from 'store'
import jwt_decode from "jwt-decode";
import {api} from 'utils/api'
import {
    logout,
    setToken,
    setUser,
    setAccessToken
} from "store/module/auth.store";
import AuthApi from "api/auth.api";
import {dialog, notification, translate} from "utils/helpers";

const translateKey = '';
const dispatch = stores.dispatch;

export const serviceLogoutAction = () => {
    dispatch(logout());
    window.location.reload();
}

/*
* Login Params
* */
export const serviceAuthDispatchLoginParams = (res, tokenRefresh = true) => {
    return new Promise((resolve, reject) => {
        const user = jwt_decode(res.accessToken)?.user;
        if (tokenRefresh)
            dispatch(setToken(res.refreshToken))
        dispatch(setAccessToken(res.accessToken))
        dispatch(setUser(user))
        resolve(true);
    })
}

export const serviceAuthDispatchLogin = async (params, navigate) => {
    try {
        const res = await api(AuthApi.login, 'post', params);
        if (res.code === 200) {
            await serviceAuthDispatchLoginParams(res);
            window.location.href = '/'
        } else if (res.code === 401) {
            await dialog({
                type: 'error',
                message: translate(translateKey + '.invalid-credentials'),
                buttonYes: false,
                buttonNoText: translate('buttons.ok')
            })
        } else if (res.code === 446) {
            let message = translate(translateKey + '.account-is-blocked-in-minutes').replace('$minute', res.duration % 60);
            if (res.duration > 60) {
                message = translate(translateKey + '.account-is-blocked-in-hours').replace('$hour', Math.floor(res.duration / 60))
            }
            await dialog({
                type: 'error',
                message,
                buttonYes: false,
                buttonNoText: translate('buttons.ok')
            })
        } else {
            await dialog({
                type: 'error',
                message: translate(translateKey + '.error-occurred'),
                buttonYes: false,
                buttonNoText: translate('buttons.ok')
            })
        }
    } catch (e) {
        return false
    }
}

export const serviceAuthRegisterMentor = async (values) => {
    const res = await api('post', AuthApi.mentorRegister, values);
    if (res.code === 200) {
        return res;
    } else if (res.code === 413) {
        notification({
            type: 'warning',
            message: translate('notification.accountAlready')
        })
    }else if (res.code === 405) {
        notification({
            type: 'warning',
            message: translate('notification.invalidEmail')
        })
    }
    return false;
}

export const serviceAuthRegisterMentee = async (values) => {
    const res = await api('post', AuthApi.menteeRegister, values);
    if (res.code === 200) {
        return res;
    } else if (res.code === 413) {
        notification({
            type: 'warning',
            message: translate('notification.accountAlready')
        })
    }else if (res.code === 405) {
        notification({
            type: 'warning',
            message: translate('notification.invalidEmail')
        })
    }
    return false;
}

export const serviceAuthLogin = async (values) => {
    const res = await api('post', AuthApi.login, values);
    if (res.code === 200) {
        await serviceAuthDispatchLoginParams(res);
        window.location.reload();
    }
    else if (res.code === 401) {
        notification({
            type: 'warning',
            message: translate('notification.loginUnauthorized')
        })
    }
}

export const serviceAuthContact = async (values) => {
    const res = await api('post', AuthApi.contact, values);
    if (res.code === 200) {
        return true;
    }
    else {
        notification({
            type: 'warning',
            message: translate('notification.global_alert')
        })
        return false;
    }
}
