import React, {useEffect, useState} from 'react';
import Modal from "components/ui/modal/Modal";
import {serviceMentorFetchById, serviceMentorFetchFile} from "services/mentor.service";
import Loading from "components/ui/loading/Loading";
import {Col, Row} from "antd";
import FormRead from "components/ui/form/FormRead";
import FormGroup from "components/ui/form/FormGroup";
import {FiDownload} from "@react-icons/all-files/fi/FiDownload";
import {serviceEnumTrainingMethods} from "services/enum.service";

function AdminMentorViewModal({visible, setVisible, tableId}) {

    const teachingMethods = serviceEnumTrainingMethods();
    const [data, setData] = useState({});

    const fetchById = async () => {
        const res = await serviceMentorFetchById(tableId);
        if (res) {
            setData(res);
        }
    }

    const handleClose = () => {
        setVisible(false)
    }

    const handleFile = async (files) => {
        const file = JSON.parse(files)[0];
        if (file) {
            const res = await serviceMentorFetchFile(file);
        }
    }

    const getTeachingMethods = () => {
        return JSON.parse(data.teachingMethods).map(i => {
            const f = teachingMethods.find(s => s.id === i);
            return f.name || ''
        }).join(', ')
    }

    useEffect(() => {
        if (tableId) {
            fetchById();
        }
    }, [tableId])

    return (
        <Modal
            title={data?.fullName}
            visible={visible}
            onClose={() => handleClose()}
            className="w-full"
        >
            <Loading loading={!data.id}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormGroup label={'İsim Soyisim'}>
                            <FormRead>{data.fullName}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Telefon'}>
                            <FormRead>{data.phone}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'E-mail'}>
                            <FormRead>{data.email}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Websitesi'}>
                            <FormRead>{data.website}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup
                            label={'Kendinizi öğrencilerinize tanıtın'}
                            prefix={
                                <button onClick={() => handleFile(data.files)}>
                                    <FiDownload/>
                                </button>
                            }
                        >
                            <FormRead>{data.description}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Mentörlük sağlayabileceğiniz konu'}>
                            <FormRead>{data.subjects ? JSON.parse(data.subjects)[0] : ''}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Ders Saat Süresi'}>
                            <FormRead>{data.duration} saat</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Eğitim türü'}>
                            <FormRead>{data.teachingMethods ? getTeachingMethods() : ''}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Eğitime başlanabilecek tarih'}>
                            <FormRead>{data.startDate}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Kontenjan'}>
                            <FormRead>{data.quota}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Eğitimi alacak kişilerde aranan spesifik bir deneyim/özellik/gereksinim var mı?'}>
                            <FormRead>{data.menteeSpecification}</FormRead>
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <FormGroup label={'Mentörlük sonrası destek'}>
                            <FormRead>{data.postMentoringSupport ? 'Evet' : 'Hayır'}</FormRead>
                        </FormGroup>
                    </Col>
                    {(data.postMentoringSupport && data.postMentoringSupportDescription) && (
                        <Col span={24}>
                            <FormGroup label={'Mentörlük sonrası destek detayları'}>
                                <FormRead>{data.postMentoringSupportDescription}</FormRead>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            </Loading>
        </Modal>
    );
}

export default AdminMentorViewModal;
