import React from 'react';

function FormTextarea(props) {
    return (
        <textarea
            className="form-control py-2"
            {...props}
        ></textarea>
    );
}

export default FormTextarea;
