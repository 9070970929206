import React, {useState, useEffect} from 'react';
import Modal from "components/ui/modal/Modal";
import {Col, Row} from "antd";
import FormGroup from "components/ui/form/FormGroup";
import {serviceEnumStatuses} from "services/enum.service";
import FormSelect from "components/ui/form/FormSelect";
import {serviceMentorPutStatus} from "services/mentor.service";
import {dialog} from "utils/helpers";

function AdminMentorStatusModal({visible, setVisible, tableData, onReload}) {

    const statuses = serviceEnumStatuses();
    const handleClose = () => {
        setVisible(false)
    }

    const [form, setForm] = useState({
        id: '',
        status: ''
    });

    const handleSubmit = async e => {
        e.preventDefault();
        const dr = await dialog({
            message: 'Durumu güncellemek istediğinizden emin misiniz?'
        })
        if (dr === 'yes') {
            const res = await serviceMentorPutStatus(form);
            if (res) {
                handleClose();
                onReload();
            }
        }
    }

    useEffect(() => {
        if (tableData?.id) {
            setForm(f => ({
                ...f,
                id: tableData?.id,
                status: tableData?.status,
            }))
        }
    }, [tableData]);

    return (
        <Modal
            title={'Statusu güncelle'}
            visible={visible}
            onClose={() => handleClose()}
            className="lg:!w-80 !w-full"
        >
            <form onSubmit={handleSubmit}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormGroup label="Status">
                            <FormSelect
                                value={form.status}
                                onChange={e => setForm(f => ({...f, status: e.target.value}))}
                                options={statuses.filter(i => i.selectBox).map(i => ({id: i.id, name: i.name}))}
                            />
                        </FormGroup>
                    </Col>
                    <Col span={24}>
                        <button type="submit" disabled={!['ACTIVE', 'REJECTED'].includes(form.status)} className="btn btn-primary btn-block">
                            Güncelle
                        </button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
}

export default AdminMentorStatusModal;
