import store from 'store'
import azAZ from "antd/lib/locale/az_AZ";
import enEn from "antd/lib/locale/en_US";
import ruRu from "antd/lib/locale/ru_RU";
import trTr from "antd/lib/locale/tr_TR";

export const antdLanguage = () => {
    const language = store.getState().appStore.language;
    if (language === 'az') return azAZ;
    else if (language === 'en') return enEn;
    else if (language === 'ru') return ruRu;
    else if (language === 'tr') return trTr;
}
